/* eslint-disable */
import React from 'react'

import ManFoldingArms from '../../images/people/man-folding-arms.png'
import ManFoldingArmsMobile from '../../images/people/man-folding-arms-mobile.png'
import CoupleInKitchen from '../../images/people/couple-in-kitchen.jpg'
import CoupleInKitchenMobile from '../../images/people/couple-in-kitchen-mobile.jpg'
import WomanWithLaptop from '../../images/people/woman-with-laptop.jpg'
import WomanWithLaptopMobile from '../../images/people/woman-with-laptop-mobile.jpg'

import { StaticImage } from 'gatsby-plugin-image'

import aetna from '../../images/brand-logos/aetna.svg'
import cigna from '../../images/brand-logos/cigna.svg'
import regence from '../../images/brand-logos/regence.svg'
import united from '../../images/brand-logos/united-healthcare.svg'
import wellcare from '../../images/brand-logos/wellcare.svg'
import mutual from '../../images/brand-logos/mutual-omaha.svg'

import NarrowBanner from '../../components/NarrowBanner'
import ResponsiveGrid from '../../components/GridLayouts/ResponsiveGrid'

import {
  Accordion,
  Billboard,
  Columns,
  Column,
  LinkButton,
  List,
  ListItem,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../../components/Header'
import footer from '../../components/Footer'

const Home = () => {
  const { rotatedNumber } = useMapi()

  const brandLogos = [
    { src: aetna, alt: 'Aetna logo' },
    { src: cigna, alt: 'Cigna logo' },
    { src: regence, alt: 'Regence logo' },
    { src: united, alt: 'United Healthcare logo' },
    { src: wellcare, alt: 'Wellcare logo' },
    { src: mutual, alt: 'Mutual of Omaha logo' },
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title:
            'Medicare Supplemental Insurance (Medigap) | ClearlinkInsurance.com',
          description:
            'Medigap vs. Medicare Advantage: Learn the differences between these two types of private-sector healthcare coverages.',
          canonical:
            'https://www.clearlinkinsurance.com/plans/supplemental-medicare',
          robots: 'follow,index',
        },
        path: '/plans/supplemental-medicare',
        promoCode: '170822',
        ringPool: 'CLI',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlinkinsurance.com/',
          siteName: 'clearlinkinsurance',
          alternateName: 'clearlinkinsurance',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Medicare Supplemental Insurance (Medigap) | ClearlinkInsurance.com',
    defaultRobots: 'follow,index',
    main: (
      <>
        <div className="hero-split-content blue-dots-background">
          <div className="wrapper">
            <Billboard
              alignImageToBottom
              mobileAlignImageToBottom
              variant="split"
              image={
                <img
                  src={ManFoldingArms}
                  alt="a man folds his arms and smiles"
                />
              }
              mobileImage={
                <img
                  src={ManFoldingArmsMobile}
                  alt="a man folds his arms and smiles"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Explore Medigap vs. Medicare Advantage Insurance Plans
                  </Typography>
                  <Typography variant="body">
                    Medigap plans, also known as Medicare Supplement plans,
                    exist to supplement the coverage provided by the federal
                    government via Original Medicare (Parts A & B). Medicare
                    Advantage plans offer the same coverage as Original
                    Medicare, plus various additional benefits.
                  </Typography>
                  <Typography variant="body">
                    Read on to learn more about the differences between Medigap
                    and Medicare Advantage plans.
                  </Typography>
                  <LinkButton
                    variant="feature"
                    to={`tel:${rotatedNumber}`}
                    color="pink"
                  >
                    Call to find a plan. {rotatedNumber}
                  </LinkButton>
                </>
              }
            />
          </div>
        </div>

        <VariableContent
          alignMainContent="Center"
          alignImageToBottom={false}
          centeredContent={false}
          customLink={null}
          className="brand-logos header"
          mainContent={
            <Typography variant="h2" style={{ color: '#02168B' }}>
              We partner with these leading insurance brands{' '}
            </Typography>
          }
        >
          <ResponsiveGrid
            images={brandLogos}
            numberOfColumns={6}
            numberOfMobileColumns={2}
            constraint="large"
            columnGap="10px"
          />
        </VariableContent>

        <VariableContent
          backgroundColor="light"
          alignMainContent="left"
          mainContent={
            <>
              <Typography variant="h2">
                Medigap vs. Medicare Advantage Plans
              </Typography>
              <Typography variant="body">
                While Medigap and Medicare Advantage are both provided by
                private insurance carriers, the similarities pretty much end
                there. Medigap, also known as Medicare supplement, exists to
                provide additional benefits and lower the costs associated with
                Original Medicare (Parts A & B, coverage delivered by the
                federal government). Medicare Advantage, on the other hand, is a
                private-sector alternative to Original Medicare, providing all
                of the same coverage as Parts A & B but with additional optional
                benefits that differ by specific plan.
              </Typography>
              <Typography variant="h4">
                What Medicare Advantage covers
              </Typography>
              <Typography variant="body">
                All Medicare Advantage policies are required to offer equivalent
                coverage for hospital and hospice care, medical insurance, and
                preventative care as the federally provided Original Medicare.
                Medicare Advantage plans also entice potential policyholders
                with additional benefits such as vision, dental, wellness
                programs, and gym memberships.
              </Typography>
              <Typography variant="h4">
                What Medicare supplemental insurance covers
              </Typography>
              <Typography variant="body">
                To enroll in a Medicare supplement plan, you must first be
                enrolled in Original Medicare (Parts A & B) to receive
                governmental coverage. Instead of replacing Original Medicare,
                Medigap plans fill in the—you guessed it—gaps in your coverage,
                helping to pay for copayments, coinsurances, and other costs
                that may come up.
              </Typography>
              <Typography variant="body">
                Medigap insurers can make available up to 10 different plans,
                each noted by a letter, explained in more detail below. These
                plans are standardized across 47 of the 50 U.S. states, so
                you’ll always know what you’re getting with a Medigap Plan C,
                for example, no matter where you are. 
              </Typography>
              <Typography variant="body">
                <strong>Note:</strong> Medigap plans are standardized
                differently in Massachusetts, Minnesota, and Wisconsin, which
                may change your coverage options.
              </Typography>
              <Typography variant="body">
                All Medigap plans cover, in part or in whole:
              </Typography>
              <List>
                <ListItem>Medicare Part A coinsurance</ListItem>
                <ListItem>Part B coinsurance</ListItem>
                <ListItem>
                  Up to 365 hospital days after Medicare benefits are used
                </ListItem>
                <ListItem>Hospice care coinsurance</ListItem>
                <ListItem>Three pints of blood</ListItem>
              </List>
              <Typography variant="body">
                Depending on the specific plan you choose, you may also receive
                additional coverage:
              </Typography>
              <List>
                <ListItem>Part A hospital deductible costs</ListItem>
                <ListItem>Skilled nursing facility coinsurance costs</ListItem>
                <ListItem>Foreign travel emergency coverage</ListItem>
              </List>
              <Typography>
                Medigap coverage <strong>does not</strong> extend to
                prescription drugs
              </Typography>
            </>
          }
        ></VariableContent>

        <SplitContent
          className="reverse-desktop"
          backgroundColor="white"
          alignImageToBottom={false}
          image={
            <img
              src={CoupleInKitchen}
              alt="a couple looks at table as they prepare food in kitchen"
            />
          }
          mobileImage={
            <img
              src={CoupleInKitchenMobile}
              alt="a couple looks at table as they prepare food in kitchen"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                How does Medigap Supplemental Insurance work?
              </Typography>
              <Typography variant="body">
                Medicare supplemental insurance plans are for individuals only,
                so if spouses both want Medigap coverage, each will need their
                own plan. You must be enrolled in Original Medicare already to
                enroll in a Medigap plan.
              </Typography>
              <Typography variant="body">
                When you get care, Medicare pays its approved amount of the
                costs. With most Medigap policies, your private supplemental
                carrier receives information directly from Medicare and pays its
                portion directly to the health care provider. With some
                carriers, you will need to make claims yourself to receive the
                funds—be sure to check upfront how your chosen carrier handles
                payment before signing up.
              </Typography>
              <Typography variant="body">
                As long as you pay your Medigap premiums, you will keep your
                plan—standardized policies renew yearly, automatically.
              </Typography>
            </>
          }
        />

        <VariableContent
          alignMainContent="center"
          backgroundColor="light"
          mainContent={
            <>
              <Typography variant="h2">
                How does Medigap Supplemental Insurance work?
              </Typography>
            </>
          }
        >
          <Stack>
            <Columns>
              <Column backgroundColor="white" className="card-padding">
                <StaticImage
                  src="../../images/icons/medigap-plan-a.svg"
                  alt="medigap-plan-a icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons left-aligned"
                />
                <Typography variant="h5">Plan A</Typography>
                <Typography variant="body">What it covers:</Typography>
                <List>
                  <ListItem>
                    The 20% of outpatient treatment care charges that Original
                    Medicare does not
                  </ListItem>
                </List>
              </Column>
              <Column backgroundColor="white" className="card-padding">
                <StaticImage
                  src="../../images/icons/medigap-plan-b.svg"
                  alt="medigap-plan-b icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons left-aligned"
                />
                <Typography variant="h5">Plan B</Typography>
                <Typography variant="body">What it covers:</Typography>
                <List>
                  <ListItem>
                    The 20% of outpatient treatment care charges that Original
                    Medicare does not
                  </ListItem>
                  <ListItem>The Medicare Part A hospital deductible</ListItem>
                </List>
              </Column>
              <Column backgroundColor="white" className="card-padding">
                <StaticImage
                  src="../../images/icons/medigap-plan-c.svg"
                  alt="medigap-plan-c icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons left-aligned"
                />
                <Typography variant="h5">Plan C</Typography>
                <Typography variant="body">What it covers:</Typography>
                <List>
                  <ListItem>
                    All out-of-pocket Medicare costs except Medicare excess
                    charges
                  </ListItem>
                </List>
              </Column>
              <Column backgroundColor="white" className="card-padding">
                <StaticImage
                  src="../../images/icons/medigap-plan-d.svg"
                  alt="medigap-plan-c icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons left-aligned"
                />
                <Typography variant="h5">Plan D</Typography>
                <Typography variant="body">What it covers:</Typography>
                <List>
                  <ListItem>
                    All out-of-pocket Medicare costs except Medicare excess
                    charges and the Medicare Part B deductible
                  </ListItem>
                </List>
              </Column>
              <Column backgroundColor="white" className="card-padding">
                <StaticImage
                  src="../../images/icons/medigap-plan-f.svg"
                  alt="medigap-plan-f icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons left-aligned"
                />
                <Typography variant="h5">Plan F</Typography>
                <Typography variant="body">What it covers:</Typography>
                <List>
                  <ListItem>All out-of-pocket Medicare costs</ListItem>
                </List>
                <Typography variant="body">
                  <strong>Note: </strong>Plan F is only available for those who
                  qualified for Medicare before 2020.
                </Typography>
              </Column>
              <Column backgroundColor="white" className="card-padding">
                <StaticImage
                  src="../../images/icons/medigap-plan-g.svg"
                  alt="medigap-plan-g icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons left-aligned"
                />
                <Typography variant="h5">Plan G</Typography>
                <Typography variant="body">What it covers:</Typography>
                <List>
                  <ListItem>
                    All out-of-pocket Medicare costs except for the Medicare
                    Part B deductible
                  </ListItem>
                </List>
              </Column>
              <Column backgroundColor="white" className="card-padding">
                <StaticImage
                  src="../../images/icons/medigap-plan-k.svg"
                  alt="medigap-plan-k icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons left-aligned"
                />
                <Typography variant="h5">Plan K</Typography>
                <Typography variant="body">What it covers:</Typography>
                <List>
                  <ListItem>
                    100% of Medicare Part A copayment or coinsurance
                  </ListItem>
                  <ListItem>
                    50% coverage for:
                    <List>
                      <ListItem>Part B coinsurance or copayment</ListItem>
                      <ListItem>Your first 3 pints of blood</ListItem>
                      <ListItem>
                        Skilled nursing facility care coinsurance
                      </ListItem>
                      <ListItem>Part A deductible</ListItem>
                    </List>
                  </ListItem>
                </List>
              </Column>
              <Column backgroundColor="white" className="card-padding">
                <StaticImage
                  src="../../images/icons/medigap-plan-l.svg"
                  alt="medigap-plan-l icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons left-aligned"
                />
                <Typography variant="h5">Plan L</Typography>
                <Typography variant="body">What it covers:</Typography>
                <List>
                  <ListItem>
                    100% of Medicare Part A copayment or coinsurance
                  </ListItem>
                  <ListItem>
                    75% coverage for:
                    <List>
                      <ListItem>Part B coinsurance or copayment</ListItem>
                      <ListItem>Your first 3 pints of blood</ListItem>
                      <ListItem>
                        Skilled nursing facility care coinsurance
                      </ListItem>
                      <ListItem>Part A deductible</ListItem>
                    </List>
                  </ListItem>
                </List>
              </Column>
              <Column backgroundColor="white" className="card-padding">
                <StaticImage
                  src="../../images/icons/medigap-plan-m.svg"
                  alt="medigap-plan-l icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons left-aligned"
                />
                <Typography variant="h5">Plan M</Typography>
                <Typography variant="body">What it covers:</Typography>
                <List>
                  <ListItem>
                    100% coverage for:
                    <List>
                      <ListItem>
                        Medicare Part A copayment or coinsurance
                      </ListItem>
                      <ListItem>Part B coinsurance or copayment</ListItem>
                      <ListItem>Your first 3 pints of blood</ListItem>
                      <ListItem>
                        Skilled nursing facility care coinsurance
                      </ListItem>
                    </List>
                  </ListItem>
                </List>
                <List>
                  <ListItem>
                    50% coverage for:
                    <List>
                      <ListItem>Part A deductible</ListItem>
                    </List>
                  </ListItem>
                </List>
              </Column>
              <Column backgroundColor="white" className="card-padding">
                <StaticImage
                  src="../../images/icons/medigap-plan-n.svg"
                  alt="medigap-plan-n icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons left-aligned"
                />
                <Typography variant="h5">Plan N</Typography>
                <Typography variant="body">What it covers:</Typography>
                <List>
                  <ListItem>
                    Partial coverage for:
                    <List>
                      <ListItem>
                        Medicare Part A copayment or coinsurance
                      </ListItem>
                      <ListItem>Part B coinsurance or copayment</ListItem>
                      <ListItem>Your first 3 pints of blood</ListItem>
                      <ListItem>
                        Skilled nursing facility care coinsurance
                      </ListItem>
                      <ListItem>Part A deductible</ListItem>
                    </List>
                  </ListItem>
                </List>
                <Typography variant="body">
                  <strong>Note:</strong> Plan N offers lower premium costs in
                  exchange for out-of-pocket copayments for the above coverage.
                </Typography>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent
          backgroundColor="white"
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                Medigap insurance plans: side-by-side
              </Typography>
              <Typography variant="body">
                View the table below for a complete picture of what’s covered
                and what’s not with each of the available Medicare supplement
                plans.
              </Typography>
            </>
          }
        >
          <div className="comparison-table bg-white medigap">
            <table className="table-main">
              <thead>
                <td>
                  <Typography variant="h6">Benefit Coverage</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan A</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan B</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan C</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan D</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan F*</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan G*</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan K</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan L</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan M</Typography>
                </td>
                <td>
                  <Typography variant="h6">Plan N</Typography>
                </td>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Typography variant="h6">Medicare Part A</Typography>
                    <Typography vairant="body">
                      coinsurance/copayment
                    </Typography>
                  </td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td>Partial</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">Medicare Part B</Typography>
                    <Typography vairant="body">
                      coinsurance/copayment
                    </Typography>
                  </td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td>50%</td>
                  <td>75%</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes**</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">
                      Blood (first three pints)
                    </Typography>
                  </td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td>50%</td>
                  <td>75%</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">Hospice</Typography>
                    <Typography variant="body">
                      coinsurance/copayment
                    </Typography>
                  </td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td>50%</td>
                  <td>75%</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">
                      Skilled nursing facility coinsurance
                    </Typography>
                  </td>
                  <td>No</td>
                  <td>No</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td>50%</td>
                  <td>75%</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">
                      Medicare Part A deductible
                    </Typography>
                  </td>
                  <td>No</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td>50%</td>
                  <td>75%</td>
                  <td>50%</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">
                      Medicare Part B deductible
                    </Typography>
                  </td>
                  <td>No</td>
                  <td>No</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td>No</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">
                      Medicare Part B excess charges
                    </Typography>
                  </td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td style={{ color: '#0D71CD' }}>Yes</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                  <td>No</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">
                      Foreign travel emergency coverage
                    </Typography>
                  </td>
                  <td>No</td>
                  <td>No</td>
                  <td>80%</td>
                  <td>80%</td>
                  <td>80%</td>
                  <td>80%</td>
                  <td>No</td>
                  <td>No</td>
                  <td>80%</td>
                  <td>80%</td>
                </tr>
                <tr>
                  <td>
                    <Typography variant="h6">Out-of-pocket limit***</Typography>
                  </td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td>N/A</td>
                  <td style={{ color: '#0D71CD' }}>$7,060</td>
                  <td style={{ color: '#0D71CD' }}>$3,530</td>
                  <td>N/A</td>
                  <td>N/A</td>
                </tr>
              </tbody>
            </table>
          </div>
          <Typography variant="legal">
            *Plans F & G offer a high deductible plan in some states
          </Typography>
          <Typography variant="legal">
            **Plan N pays 100% of the costs of Part B services, except for
            copayments for some office visits and some emergency room visits.{' '}
          </Typography>
          <Typography variant="legal">
            ***Plans K & L show how much they'll pay for approved services
            before you meet your out-of-pocket yearly limit and Part B
            deductible. After you meet them, the plan will pay 100% of your
            costs for approved services.
          </Typography>
          <LinkButton variant="" to={`tel:${rotatedNumber}`} color="pink">
            Call to find a plan. {rotatedNumber}
          </LinkButton>
        </VariableContent>

        <SplitContent
          backgroundColor="light"
          alignImageToBottom={false}
          image={
            <img
              src={WomanWithLaptop}
              alt="a woman smiles while working on laptop"
            />
          }
          mobileImage={
            <img
              src={WomanWithLaptopMobile}
              alt="a woman smiles while working on laptop"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Medigap vs. Medicare Advantage: how to choose
              </Typography>
              <Typography variant="body">
                Because Medigap requires Original Medicare enrollment, you
                cannot enroll in both Medicare Advantage and Medicare
                supplemental coverage. Both options require monthly premium
                costs.
              </Typography>
              <Typography variant="body">
                Medicare Advantage may offer the coverage and additional
                benefits you want if you are okay working with a pre-chosen
                network of physicians, hospitals, and other health care
                providers. If, on the other hand, you want access to more
                options, Medigap plans offer the freedom to see any doctor or
                visit any hospital that accepts Medicare.
              </Typography>
              <Typography variant="body">
                Both options have their benefits and their drawbacks. We
                recommend comparing plans and benefits before you enroll in
                Medicare to choose the direction that fits you best.
              </Typography>
            </>
          }
        />

        <VariableContent
          alignMainContent="left"
          mainContent={
            <Typography variant="h2">
              Frequently asked questions about Medigap Plans
            </Typography>
          }
        >
          <Stack spacing="xxl" alignMainContent="center">
            <Accordion
              items={[
                {
                  title:
                    'Who is eligible for Medicare Supplemental Insurance Plans?',
                  content: (
                    <>
                      <Typography>
                        You must enroll in Original Medicare (Parts A & B) for
                        coverage through the federal government to be eligible
                        for Medicare supplemental insurance. Once you have
                        enrolled in Medicare Parts A & B, your enrollment period
                        lasts for six months; during this time, you can choose
                        the Medigap plan that best fits your needs and budget.
                      </Typography>
                    </>
                  ),
                },
                {
                  title: 'What isn’t covered by Medigap plans?',
                  content: (
                    <>
                      <Typography>
                        Because Medigap plans supplement the coverage you get
                        from Original Medicare, any costs associated with
                        additional benefits you might get from a Medicare
                        Advantage or Medicare Part D prescription drug plan are
                        not covered.
                      </Typography>
                      <Typography>
                        If you need any of the following coverage, you may want
                        to consider a Medicare Advantage and/or Medicare Part D
                        plan instead of going with Original Medicare plus
                        Medigap.
                      </Typography>
                      <List>
                        <ListItem>
                          Long-term (nursing home) care coverage
                        </ListItem>
                        <ListItem>Vision coverage</ListItem>
                        <ListItem>Dental coverage</ListItem>
                        <ListItem>Hearing aid coverage</ListItem>
                        <ListItem>Private duty nursing coverage</ListItem>
                        <ListItem>Prescription drug coverage</ListItem>
                      </List>
                    </>
                  ),
                },
                {
                  title: 'When is the best time to purchase a Medigap plan?',
                  content: (
                    <>
                      <Typography>
                        The Medigap enrollment period lasts for six months after
                        you enroll in Original Medicare (Parts A & B) for the
                        first time. Depending on your state, you may be unable
                        to enroll in Medigap after this period; if you can, it
                        may come at a much higher cost.
                      </Typography>
                    </>
                  ),
                },
                {
                  title:
                    'Are my Medigap options limited based on the state I live in?',
                  content: (
                    <>
                      <Typography>
                        In 47 of the 50 states, available Medigap plans are
                        standardized.
                      </Typography>
                      <Typography>
                        Massachusetts, Minnesota, and Wisconsin each have
                        separate regulations and plan types.
                      </Typography>
                      <Typography>
                        To find the most accurate information on the Medigap
                        options available in your area, speak to one of our
                        expert, licensed agents.
                      </Typography>
                    </>
                  ),
                },
              ]}
            />
          </Stack>
        </VariableContent>

        <div className="dual-button-banner">
          <NarrowBanner
            backgroundColor="#F004B9"
            leftButton={
              <LinkButton to={`tel:${rotatedNumber}`} color="white">
                <span className="mobile-text">Call Now</span>
                <span className="desktop-text">
                  Call Now to Speak to an Agent {rotatedNumber}
                </span>
              </LinkButton>
            }
            rightButton={
              <LinkButton
                to="https://my.clearlinkinsurance.com/login"
                color="white"
              >
                User Portal Login
              </LinkButton>
            }
          />
        </div>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home
